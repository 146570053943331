import React from "react";
import Accent from "../Accent";

import styles from "./styles.module.less";

const IntroBlock = ({ header, par, par2 }) => {
    return (
        <section className={styles.IntroBlock}>
            <div className={`${styles.inner} ${styles.absolute} ${styles.bg}`}>
                <h1 className={styles.introTitle}>{header}</h1>
                <Accent align="center" />
                <div className={styles.par}>{par}</div>
                <div className={styles.par}>{par2}</div>
            </div>
        </section>
    );
};

export default IntroBlock;
