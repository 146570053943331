import React, { Component } from "react";
import styles from "./styles.module.less";
import background from "./../../../images/boots.jpg";

class FirstCinemaTop extends Component {
    constructor(props) {
        super(props);
        this.backRef = React.createRef();
        this.frontRef = React.createRef();
    }
    componentDidMount() {
        setTimeout(() => {
            if (this.frontRef.current) {
                this.frontRef.current.play();
            }
            return;
        }, 2000);
    }
    render() {
        let backVideo =
            "https://ucarecdn.com/63729330-ee9d-40a6-8d5b-8519bff6a9ee/coffeecup-window.mp4";

        return (
            <section className={styles.FirstCinemaTop}>
                <div className={styles.bgVideo}>
                    <video
                        className={styles.back}
                        ref={this.backRef}
                        loop
                        autoPlay
                        muted
                        playsInline>
                        <source src={backVideo} type="video/mp4" />
                        <source src={backVideo} type="video/ogg" />
                        <img
                            src={background}
                            title="Your browser does not support the video tag."
                            alt=""
                        />
                    </video>
                </div>
            </section>
        );
    }
}

export default FirstCinemaTop;
