import React from "react";
import styles from "./styles.module.less";
import backgroundImage from "./Line_large.png";

interface AccentProps {
    align: String;
}
const Accent = (props: AccentProps) => {
    const { align = "center" } = props;
    const bg = {
        backgroundImage: `url(${backgroundImage})`,
        margin: align === "center" ? "12px auto" : "12px 0",
    };

    return (
        <div className={styles.AccentBlock}>
            <hr {...props} className={styles.Accent} style={bg} />
        </div>
    );
};
export default Accent;
