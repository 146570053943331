import React from "react";

import IntroBlock from "../IntroBlock";
import FirstCinemaTop from "../cinemagraphs/FirstCinemaTop";

import GettingStartedIcon from "./images/Icon_Getting_started_small.png";
import LoanTypesIcon from "./images/Icon_Homeloan_Small.png";
import CalculateIcon from "./images/Icons_Calculate_Active.png";
import FindTeamIcon from "./images/Icons_FindTeam_Active.png";

import styles from "./styles.module.less";

const FirstTimeHomebuyer = props => {
    const heroBG = "#F6F6F6";

    const header = (
        <span>
            First-time
            <br /> homebuyer
        </span>
    );
    const par = (
        <>
            Buying your first home is a major event. And it’s normal to feel a
            simultaneous sense of <em>excitement</em> and{" "}
            <em>where do I start!?</em> So let us add some clarity: start right
            here with these basic first-time homebuyer tips and tools.
        </>
    );

    return (
        <div
            className={styles.FirstTimeHomebuyer}
            style={{
                padding: 0,
                margin: 0,
                backgroundColor: heroBG,
                textAlign: "center",
            }}>
            <FirstCinemaTop />

            <IntroBlock header={header} par={par} />
            <section className={styles.FirstTimeHomebuyer}>
                <div className={styles.subcontainer}>
                    <a href="#getstarted">
                        <div className={styles.product}>
                            <div className={styles.icon}>
                                <img
                                    src={GettingStartedIcon}
                                    alt="Get Started Buying a Home - Get Started Icon | Bay Equity Home Loans"
                                />
                            </div>
                            <h1 className={styles.caption}>
                                Get Started
                                <br />
                            </h1>
                        </div>
                    </a>
                    <a href="#loantypes">
                        <div className={styles.product}>
                            <div className={styles.icon}>
                                <img
                                    src={LoanTypesIcon}
                                    alt="Learn the Different Types of Loans - Learn the Loan Types Icon | Bay Equity Home Loans"
                                />
                            </div>

                            <h1 className={styles.caption}>
                                {" "}
                                Learn the Loan Types
                            </h1>
                        </div>
                    </a>

                    <a href="#findyourteam">
                        <div className={styles.product}>
                            <div className={styles.icon}>
                                <img
                                    src={FindTeamIcon}
                                    alt="Find Your Bay Equity Home Loan Team - Find Your Team Icon | Bay Equity Home Loans"
                                />
                            </div>
                            <h1 className={styles.caption}>Find Your Team</h1>
                        </div>
                    </a>
                    <a href="#calculate">
                        <div className={styles.product}>
                            <div className={styles.icon}>
                                <img
                                    src={CalculateIcon}
                                    alt="Calculate Your Home Loan Payment - Calculate Your Payment Icon | Bay Equity Home Loans"
                                />
                            </div>
                            <h1 className={styles.caption}>
                                {" "}
                                Calculate Your Payment
                            </h1>
                        </div>
                    </a>
                </div>
            </section>
        </div>
    );
};

export default FirstTimeHomebuyer;
