import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import { mapBlogs } from "../../lib/utility";

import Layout from "../../components/Layout";
import Header from "../../components/Header";
import BlogRoll from "../../components/BlogRoll";
import CopyBlock from "../../components/CopyBlock";
import FirstTimeHomebuyer from "../../components/FirstTimeHomebuyer";
import GetStartedIcon from "./images/Icon_GettingStarted_Large.png";
import LoanTypesIcon from "./images/Icon_Homeloan_Large.png";
import FindTeamIcon from "./images/Icons_FindTeam_large.png";
import CalculateIcon from "./images/Icons_Calculate_Large.png";

import styles from "./styles.module.less";

/*const handleClick = (e) => {
    try {
        element.scrollIntoView({ options })
        return false;
    } catch (error) {
        element.scrollIntoView(false);
    }
}*/

const FTHBPage = ({ data }) => {
    const bt = "Learn More";

    const header = <Header active={["first-time-homebuyer"]} />;

    const { corpBlogs } = data;
    const { edges: corpBlobPosts = [] } = corpBlogs || {};
    const corporateBlogs = mapBlogs(corpBlobPosts);

    return (
        <Layout hasHero={true} header={header}>
            <Helmet>
                <title>
                    Buying Your First Home - First-Time Homebuyer | Bay Equity
                    Home Loans
                </title>
                <meta
                    name="description"
                    content="Ready to buy your first home? Let Bay Equity Home Loans help you through every step of the home buying process, from research to purchase. Contact us today."
                />
            </Helmet>
            <FirstTimeHomebuyer />
            <CopyBlock
                title={
                    <span>
                        <span className={styles.Anchor} id="getstarted" />
                        Start living your dream today
                    </span>
                }
                description={
                    <span>
                        So, you’re ready to buy your first home. First of all,
                        congratulations. Second, let’s get started. There are
                        many steps to buying a home, and we want to help you
                        every step of the way, starting&nbsp;now.
                    </span>
                }
                buttonTitle={"Get Started"}
                href="/get-started"
                icon={GetStartedIcon}
                flexD={"row-reverse"}
                name="getstarted"
                alt="Start Living Your Dream Today - Get Started Buying a Home | Bay Equity Home Loans"
            />
            <CopyBlock
                title={
                    <span>
                        <span className={styles.Anchor} id="loantypes" />
                        Get to know the types of loans
                    </span>
                }
                description={
                    <span>
                        The only similarity between two home loans is that
                        they’re both very different. Let us show you the types
                        of loans available to you. Once you know your options,
                        we can help figure out your next move.
                    </span>
                }
                buttonTitle={bt}
                href="/home-loans"
                icon={LoanTypesIcon}
                flexD={"row"}
                name="loantypes"
                alt="Get to Know the Different Types of Home Loans - Buy Your First Home | Bay Equity Home Loans"
            />
            <div className={styles.middlePhoto} />
            <CopyBlock
                title={
                    <span>
                        <span className={styles.Anchor} id="findyourteam" />
                        Find your team
                    </span>
                }
                description={
                    <span>
                        You’re ready to begin the process of buying your first
                        home. We’re proud of you. And we’re here to support your
                        home-buying dream like it’s&nbsp;our&nbsp;own.
                    </span>
                }
                buttonTitle={bt}
                href="/find-your-loan-officer"
                icon={FindTeamIcon}
                flexD={"row-reverse"}
                name="findyourteam"
                alt="Find Your Bay Equity Home Loan Team - Get Started Buying a Home | Bay Equity Home Loans"
            />
            <CopyBlock
                title={
                    <span>
                        <span className={styles.Anchor} id="calculate" />
                        Calculate your payments
                    </span>
                }
                description={
                    <span>
                        When aiming toward the goal of homeownership, it helps
                        when you can see your target. Use these mortgage
                        calculators to figure out what it will take to get into
                        your&nbsp;first&nbsp;home. We’ll help you get specifics
                        and take into account your loan options, goals and
                        financial situation.
                    </span>
                }
                buttonTitle={bt}
                href="/loan-calculators"
                icon={CalculateIcon}
                name="calculate"
                alt="Calculate Your Home Loans Payments - Buy Your First Home | Bay Equity Home Loans"
            />
            {corporateBlogs.length >= 3 && (
                <BlogRoll
                    corporateBlogs={corporateBlogs}
                    blogs={corporateBlogs}
                    showBlogs={true}
                    word="our"
                    url="first-time-homebuyer"
                />
            )}
        </Layout>
    );
};

export default FTHBPage;

export const pageQuery = graphql`
    query FTHBQuery {
        corpBlogs: allBlog(
            sort: { fields: [date], order: DESC }
            filter: {
                type: { eq: "corporate" }
                categories: { in: ["First-Time Homebuyer"] }
            }
        ) {
            edges {
                node {
                    id
                    html
                    slug
                    title
                    date
                    feature
                    categories
                    tags
                    image
                    author {
                        slug
                        profile {
                            name
                        }
                        gallery {
                            photo
                        }
                    }
                    date
                    feature
                }
            }
        }
    }
`;
